<template>
    <div>
        <transition
            name="show-alert"
            enter-active-class="transform ease-out duration-300 transition"
            enter-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        >
            <div v-show="show" class="max-w-sm w-full bg-white shadow-lg rounded-lg ring-1 ring-black/5 relative overflow-hidden pointer-events-auto z-50">
                <div class="p-4">
                    <div class="flex items-start">
                        <!-- alert icon -->
                        <div class="shrink-0 w-6">
                            <svg v-if="alert.type === 'error'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="w-6 h-6 text-red-400">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                            </svg>
                            <svg v-else-if="alert.type === 'info'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-6 h-6 text-blue-400">
                                <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                            </svg>
                            <svg v-else xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-green-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                            </svg>
                        </div>

                        <div class="w-0 flex-1 pt-0.5 ml-3">
                            <p
                                class="text-sm font-medium text-gray-900"
                                v-show="alert.title"
                                v-text="alert.title"
                            />
                            <p
                                class="mt-1 text-sm text-gray-500"
                                v-show="alert.description"
                                v-text="alert.description"
                            />
                        </div>

                        <div class="ml-4 shrink-0 flex">
                            <button
                                class="rounded-md inline-flex text-secondary-400 hover:text-secondary-500 focus:outline-hidden"
                                @click="show = false"
                            >
                                <span class="sr-only">Close</span>
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: 'AlertItem',
    props: {
        alert: {required: true, type: Object},
    },
    data: () => ({
        show: true,
    }),
    mounted(){
        setTimeout(() => this.show = false, this.alert.timeout);
    }
};
</script>
