<script setup>
import { computed, ref } from 'vue';
import BaseButton from '@/components/layouts/BaseButton.vue';
import BaseLabel from '@/components/layouts/BaseLabel.vue';
import BaseInput from '@/components/layouts/BaseInput.vue';
import InvalidFeedback from '@/components/layouts/InvalidFeedback.vue';
import Modal from '@/components/layouts/Modal.vue';
import { useForm } from '@/composables/form.js';
import * as Sentry from '@sentry/browser';

const modal = ref();

const loading = ref(false);

const submitted = ref(false);

const form = useForm({
    email: null,
});

const disabled = computed(() => {
    return loading.value
        || !form.fields.email;
});

async function submit() {
    loading.value = true;

    try {
        await form.post('/consumer-report/resend');
    } catch (error) {
        loading.value = false;

        Sentry.captureException(error);

        console.error(error);

        return;
    } finally {
        loading.value = false;
    }

    submitted.value = true;

    form.reset();
};
</script>

<template>
    <div>
        <a href="#" @click="modal.show()" class="flex items-center space-x-2 p-3 rounded-md bg-gray-50 ring-1 ring-inset ring-blue-700/20 sm:p-4 hover:ring-blue-700/40 hover:shadow-sm">
            <div class="shrink-0">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1" stroke="currentColor" class="w-8 h-8 text-gray-400">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.125 2.25h-4.5c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125v-9M10.125 2.25h.375a9 9 0 0 1 9 9v.375M10.125 2.25A3.375 3.375 0 0 1 13.5 5.625v1.5c0 .621.504 1.125 1.125 1.125h1.5a3.375 3.375 0 0 1 3.375 3.375M9 15l2.25 2.25L15 12" />
                </svg>
            </div>

            <div class="text-sm">
                <div class="text-left text-gray-600">
                    Did you order a personal background check?
                </div>

                <div class="text-left">
                    <div class="font-medium text-blue-500 text-start">
                        Click here to get the link to your report <span aria-hidden="true">&rarr;</span>
                    </div>
                </div>
            </div>
        </a>

        <Modal ref="modal" @modal-closed="form.reset(); submitted = false" with-dismiss>
            <div v-if="submitted">
                <div>
                    <h3 class="text-2xl font-extrabold leading-6 text-gray-900">
                        Report Request Submitted
                    </h3>

                    <p class="mt-2 text-gray-500">
                        A link to your report emailed to you shortly.
                    </p>
                </div>
            </div>
            <div v-else>
                <div>
                    <h3 class="mt-4 text-2xl font-extrabold leading-6 text-gray-900">
                        Report Link Request
                    </h3>

                    <p class="mt-2 text-sm text-gray-500">
                        Please fill out the following information and we'll re-send a link to your personal background check.
                    </p>
                </div>

                <div class="mt-5">
                    <BaseLabel class="sr-only">
                        Email Address
                    </BaseLabel>

                    <BaseLabel for="email" class="block text-sm font-medium text-gray-700">
                        Email Address
                    </BaseLabel>

                    <BaseInput
                        type="email"
                        v-model="form.fields.email"
                        placeholder="Enter your email address"
                        class="mt-1"
                        @input="form.errors.forget('email')"
                        :error="form.errors.has('email')"
                    />

                    <InvalidFeedback :error="form.errors.has('email')">
                        {{ form.errors.get('email') }}
                    </InvalidFeedback>
                </div>

                <div class="mt-5">
                    <BaseButton
                        :disabled="disabled"
                        :processing="form.busy"
                        processing-text="Submitting..."
                        @click="submit"
                        md
                        primary
                    >
                        Submit

                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" class="w-5 h-5 ml-2">
                            <path fill-rule="evenodd" d="M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z" clip-rule="evenodd" />
                        </svg>
                    </BaseButton>
                </div>
            </div>
        </modal>
    </div>
</template>
