import * as Sentry from '@sentry/vue';
import cookies from '@/helpers/cookies.js';
import Vue from 'vue';

export function sentryInit() {
    Sentry.init({
        Vue,
        dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
        environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
        integrations: [Sentry.browserTracingIntegration()],
        logErrors: import.meta.env.VITE_SENTRY_ENVIRONMENT !== 'production',
        tracePropagationTargets: ['localhost', import.meta.env.VITE_BASE_URL, /^\//],
        tracesSampleRate: import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE,
        sendDefaultPii: true
    });

    if (cookies.has('user')) {
        const user = JSON.parse(cookies.get('user'));

        Sentry.setUser(user);
    }

    if (cookies.has('request_id')) {
        Sentry.setTag('request_id', cookies.get('request_id'));
    }
}
