<template>
    <span
        @mouseleave="reset()"
        @click.stop="copyToClipboard()"
        :class="[
            'tooltip cursor-pointer',
            ...customClass,
            tooltipRight ? '' : 'tooltip-top',
        ]"
        role="menuitem"
        tabindex="-1"
        :data-tooltip="copied ? 'Copied!' : 'Click to copy'"
    >
        <slot />
    </span>
</template>

<script>
export default {
    name: 'CopyableItem',
    props: {
        value: {required:true, type: String},
        tooltipRight: { default:false, type:Boolean },
        customClass: {
            type: Array,
            default(){
                return [];
            },
        },
    },
    data: () => ({
        copied: false,
    }),
    methods: {
        copyToClipboard(){
            navigator.clipboard
                .writeText(this.value)
                .then(() => this.copied = true);
        },
        reset(){
            if(this.copied){
                this.copied = false;
            }
        },
    },
};
</script>
