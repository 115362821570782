<template>
    <div class="fixed z-50 inset-0 flex items-end justify-center px-4 py-6 pointer-events-none sm:p-5 sm:pt-4 sm:items-start sm:justify-end">
        <div class="max-w-sm w-full space-y-2 pointer-events-auto flex flex-col-reverse">
            <alert v-for="(alert, index) in alerts" :key="index" :alert="alert" />
        </div>
    </div>
</template>

<script>
import Alert from './AlertItem.vue';

export default {
    name: 'SimpleAlerts',
    components:{
        'alert': Alert,
    },
    data(){
        return {
            alerts: [],
        };
    },
    methods: {
        addAlert(options){
            const alert = this.parseAlert(options);

            this.alerts.push(alert);
        },
        parseAlert(options){
            return Object.assign({
                title: 'Successfully saved!',
                type: 'success',
                timeout: 3500,
            }, options);
        },
    },
    created(){
        Bus.$on('alert', this.addAlert);
    },
};
</script>
