export default {
    success(description = 'The operation was successful', title = 'Successfully saved!') {
        Bus.$emit('alert', {
            description,
            title
        });
    },

    error(description = 'An error occurred') {
        Bus.$emit('alert', {
            type: 'error',
            title: 'Error',
            description
        });
    },

    info(description) {
        if (typeof description !== 'string') {
            throw new Error('The description is missing or is not a string.');
        }

        Bus.$emit('alert', {
            type: 'info',
            title: 'Information',
            description
        });
    },
};

